import React, { useEffect, useState } from "react";
import './style.css';
import centerLogo from '../../images/homeCenterLogo.png';
import value1 from '../../images/challenge.png';
import value2 from '../../images/careful.png';
import value3 from '../../images/training.png';
import { Link } from "react-router-dom";
import SolutionPage from "../Solution";
import NodeMasterPage from "../NodeMaster";
import FutureIndustryPage from "../FutureIndustry/Mobile";
import ServicePage from "../Service";

export default function Home(){
    const [currentCount, setCurrentCount] = useState(0); // 현재 방문자 수를 저장할 상태
    const systemId = 'AntsNest';

    // 당일 방문자 수를 업데이트하고 현재 카운트를 받아오는 함수
    const updateVisitorCount = async () => {
      try {
        const response = await fetch(`api/updateVisitorCount?systemId=${systemId}`, {
          method: 'GET', // GET 메소드 사용
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCurrentCount(data.currentCount); // 응답으로 받은 현재 방문자 수를 상태로 저장
      } catch (error) {
        console.error('Error fetching visitor count:', error);
      }
    };
    // const SolutionPage = createRef();

    // 퀵메뉴 호버시 배경 이미지 바뀜 설정
    const [bgImg , setBgImg] = useState('default');

    const handleMouseEneter = (image) =>{
        setBgImg(image);
    };
    const handleMouseLeave = ()=>{
        setBgImg('default');
    };

    // 컴포넌트가 마운트될 때 당일 방문자 수 업데이트 함수를 호출
    useEffect(() => {
        updateVisitorCount();
        }, []); // 빈 의존성 배열을 사용하여 컴포넌트 마운트 시에만 호출되도록 설정

    return(
        <div className="Home">
            {/* 메인슬라이드 */}
            <div className="MainSlide">
                <div className={`menu-bg ${bgImg}`}>
                    {/* 슬라이드 텍스트 */}
                    <div className="MainSlideTxt">
                        <h1>고객 맞춤의 스마트 솔루션,<br/>
                            앤츠네스트 입니다.
                        </h1>
                        <p>
                            더 많은 기업에게 기회를 제공하기 위해<br/>
                            VR/AR기술을 활용하여 교육의 문턱을 낮추고,<br/>
                            대중화하는 것을 목표로 합니다.
                        </p>
                    </div>
                    {/* 퀵메뉴 */}
                    <ul className="quickMenu">
                        <Link ref={FutureIndustryPage} to='/FutureIndustry' className="quickLink">
                            <li onMouseEnter={()=> handleMouseEneter('image1')} onMouseLeave={handleMouseLeave}>미래산업
                                <ul className="quickSubMenu">
                                    <li>VR/AR</li>
                                    <li>Web3</li>
                                    <li>메타버스</li>
                                    <li>인공지능</li>
                                    <li>디지털트윈</li>
                                </ul>
                            </li>
                        </Link>
                        <Link ref={SolutionPage} to='/Solution'>
                            <li onMouseEnter={()=> handleMouseEneter('image2')} onMouseLeave={handleMouseLeave}>SI 솔루션
                                <ul className="quickSubMenu">
                                    <li>시스템 통합 구축</li>
                                    <li>스마트 팩토리 [MES]</li>
                                    <li>전사적 자원관리 [ERP] </li>
                                    <li>공급망 관리 [SCM]</li>
                                    <li>고객 관계 관리 [CRM]</li>
                                </ul>
                            </li>
                        </Link>
                        <Link ref={ServicePage} to='/Service'>
                            <li onMouseEnter={()=> handleMouseEneter('image3')} onMouseLeave={handleMouseLeave}>서비스
                                <ul className="quickSubMenu">
                                    <li>교육</li>
                                    <li>클라우드</li>
                                    <li>버츄얼 촬영 스튜디오</li>
                                </ul>
                            </li>
                        </Link>
                        <Link ref={NodeMasterPage} to='/NodeMaster'>
                            <li onMouseEnter={()=> handleMouseEneter('image4')} onMouseLeave={handleMouseLeave}>커뮤니티
                                <ul className="quickSubMenu">
                                    <li>노드마스터</li>
                                    <li>앤츠톡</li>
                                    <li>3D리소스</li>
                                </ul>
                            </li>
                        </Link>
                    </ul>
                </div>
            </div>
            {/* 중간 섹션 */}
            <div className="HomeContents">
                <div className="HomeContainer">
                    {/* 윗부분 내용 */}
                    <div className="TopContents">
                        <img src={centerLogo}/>
                        <div className="centerTxt">
                            <h1>
                                ANTSNEST는 해당 사업을 위해 7년 이상 연구해왔으며,<br/>
                                고객 맞춤형 솔루션 구축으로 <span>스마트 솔루션 개발 업체의 선두 주자</span>입니다.
                            </h1>
                        </div>
                    </div>
                    {/* 아랫부분 내용 */}
                    <div className="BottomContents">
                        <h2>Core Values</h2>
                        <div className="coreValues">
                            <div className="coreValuseBox">
                                <img src={value1}/>
                                <h3>도전</h3>
                                <p> 아직 누구도 가보지 않았던, 알려지지 않은 영역으로의 탐험에 주저하지 않습니다. 우리는 끊임없이 새로운 가능성을 찾아 나가며, 이를 통해 우리의 고객, 파트너, 그리고 사회에 혜택을 제공하려 노력합니다.</p>
                            </div>
                            <div className="coreValuseBox">
                                <img src={value2}/>
                                <h3>세심함</h3>
                                <p> 앤츠네스트는 보이지 않는 일상의 보석에서 광채를 발견합니다. 모두가 눈에 보이는 큰 것에 집중할 떄, 우리는 모래알과 같은 작고 사소한 부분에서 가치를 발견해냅니다.</p>
                            </div>
                            <div className="coreValuseBox">
                                <img src={value3}/>
                                <h3>인재 양성</h3>
                                <p> 인재의 발굴과 양성이 기업의 미래를 좌우하는 것은 자명한 사실이나, 이는 단지 회사 내부의 인재들에게만 국한되는 것이 아닙니다. 우리의 가치는 사회 전체에 걸쳐 인재를 발굴하고, 능력을 개발하고, 가능성을 키워나가는 데에 있습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label style={{color:'#fff'}}>visitor : {currentCount}</label>
        </div>
    );
    
};

