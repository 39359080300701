import React from 'react';
import './style.css';



export default function HrCard(props) {
    return (
        <div className="HrCard">
          <img src={props.Photo} alt="Dexterity" />
          <div className="containerBox" >
            <h2>{props.Name}</h2>
            <p className="title">{props.Work} </p>
            <p className='Worktitle'>[{props.Title}] <br/>{props.Work2}</p>
            <p>{props.Email}</p> 
            <button className="button">Contact</button>
          </div>
      </div>
    );
}