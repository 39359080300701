import './Divider.css';

function Divider() {
    return (
        <div className="divider-container">
            <div className="divider">준비중인 앱</div>
        </div>
    );
  }
  
  export default Divider;