import React from 'react';
import './Mstyle.css';



export default function HrCard(props) {
    return (
        <div className="MHrCard">
        <img width="300px" height="300px" src={props.Photo} alt="Dexterity" />
        <div className="MHrcontainerBox" >
          <h2>{props.Name}</h2>
          <p className="MHrtitle">{props.Work}</p>
          <p className='MHrWorktitle'>[{props.Title}]<br />
            {props.Work2}
          </p>
          <p>{props.Email}</p> 
        </div>
        <button className="MHrbutton">Contact</button>
      </div>
    );
}
