import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser, faTimes } from "@fortawesome/free-solid-svg-icons";
import MLogo from '../../images/AntsMainLog.png';

export default function Navigator() {
    const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #000F23;
    overflow-x: hidden; 
    

    

    .M_logo {
        float: left;
        padding-top: 1vh;
    }
  
    .M_header__menulist {
      list-style: none;
      gap: 1rem;
      display: flex;
      align-items: center;
      padding: 0;
    }
    
    .M_header__menulist li:hover {
      color: #4eeafe;
    }
  
    li {
      padding: 0;
    }
  
    .toggle {
      display: none;
      font-size: 1.3rem;
    }
  

  
    @media screen and (max-width: 850px) {
      flex-wrap: wrap;
      .M_logoImg {
        width: 110px;
      }



      .M_header__menulist {
        display: ${(props) => (props.isToggled ? "flex" : "none")};
        flex-direction: column;
        width: 100%;

      }
  
      .M_header__menulist li,
      .M_header__right li {
        margin: 0;
        padding: 0;
        font-size: 1rem;
        text-align: center;
        

      }
  
      .toggle {
        display: block;
        margin-right: 0;
      }
      

    }
  `;

    
    const [isToggled, setIsToggled] = useState(false);
    const [userToggled] = useState(false);

    return (

        <Header isToggled={isToggled} userToggled={userToggled} style={{padding:' 1rem 0rem'}}>
            <Link to={'/'} className="M_home"><div className="M_logo"><img className="M_logoImg" src={MLogo} /></div></Link>

            <div
                className="toggle"
                onClick={() => {
                    setIsToggled(!isToggled);
                }}
            >
                <FontAwesomeIcon icon={!isToggled ? faBars : faTimes} />
            </div>

            <ul className="M_header__menulist">
            <hr style={{ margin: "0", border:'1px solid #0F263B' }} width="100%"  />

                <Link to={'/Company'} className="M_company" style={{ color: '#fff', textDecoration: 'none' }}> <li>COMPANY </li> </Link>
                <Link to={'/Business'} className="M_business" style={{ color: '#fff', textDecoration: 'none' }}> <li>BUSINESS </li> </Link>
                <Link to={'/Contact'} className="M_contact" style={{ color: '#fff', textDecoration: 'none' }}> <li>CONTACT </li> </Link>
            </ul>

        </Header>

    );
}
