import React from 'react';
import './style.css';
import f_QR from '../../images/footerQR.png'
import footer from '../../images/footer_big.png'

export default function Footer(){
    const footerSectionStyle = {
        width: '80%',
        
    }

    return (
        <div className='App-footer'>
            
                <div className='M_footerInfo'>
                    {/* 왼쪽 텍스트 */}
                    <div className='M_footerTxt'>
                        <p>앤츠네스트 | 이메일 : admin@antsnest.co.kr </p> <p> 대표자 : 용수현 | 전화번호 : 070-7797-0814</p>
                        <p>서울시 강남구 도곡로3길 13(르-메이에르 타운) 206-1호 </p>
                        <p>© ANTSNEST DEVELOPER CENTER Co.Ltd. All Rights Reserved.</p>
                    </div>

                    {/* 오른쪽 큐알 이미지 */}
                    <div className='M_footerQR'>
                        <img src={f_QR}/>
                    </div>
                </div>
        </div>



    );
}