import './style.css';
import NodeImg1 from '../../images/NodeImg01.png';
// import NodeImg2 from '../../images/NodeImg02.png';
import NodeImg3 from '../../images/NodeImg03.png';
import NodeImg4 from '../../images/NodeImg04.png';
import FileDown from '../../images/downBtn.png';

export default function NodeMaster(){
    return(
        <div className='NodeMasterContents'>
            <div className='HomeContainer'>
                {/* 상단 텍스트 */}
                <div className="NodeMasterTopTxt">
                    <h1>생각을 정리하는<br/>가장 스마트한 방법</h1>
                    <p>떠오르는 모든 생각을 빠르고 쉽게 기록하고 관리하세요</p>
                </div>
                <div className="NodeMasterSection">
                    <h1>NodeMaster</h1>
                    {/* 첫번째 섹션 */}
                    <div className='NodeMasterFirt'>
                        {/* 이미지 */}
                        <div className='NodeImgBox'>
                            <img src={NodeImg1}/>
                            {/* <img src={NodeImg2}/> */}
                        </div>
                        {/* 다운버튼 */}
                        <div className='NodeDownload'>
                            <h3>다운로드</h3>
                            <div className='NodeDownBtn'>
                                <div className='NDBtn'>
                                    <a>
                                    {/* <a  href={'files/NodeMaster.msi'} download="NodeMaster.msi"> */}
                                        <p><span>클라우드</span>노드마스터 준비중</p>
                                        <img src={FileDown} />
                                    </a>
                                </div>
                                <div className='NDBtn'>
                                    <a href={'files/NodeMasterLocal.msi'}download="NodeMasterLocal.msi">
                                        <p><span>로컬</span>노드마스터 다운로드 V 0.01</p>
                                        <img src={FileDown}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 두번째 섹션 */}
                    <div className='NodeMasterSecond'>
                        <div className='NodeContents'>
                            <img src={NodeImg3}/>
                            <div className="NodeFirstBox">
                                <h2>데이터 정리 수집 프로그램</h2>
                                <div className="NodeCircle">
                                    <p>트리뷰와 웹 형태로 많은 자료를 목차형태로 볼 수 있는 정리 도구</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <ul>
                                            <li>계층 구조를 통한 내용의 카테고리화</li>
                                            <li>강력한 검색 기능</li>
                                            <li>체크리스트 기능 지원</li>
                                            <li>데이터의 안전한 저장/보호</li>
                                            <li>직관적인 UI</li>
                                            <li>단축키 활용으로 빠른 편집</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='NodeContents'>
                            <img src={NodeImg4}/>
                            <div className="NodeFirstBox">
                                <h2>Guide</h2>
                                <div className="NodeCircle">
                                    <p>하루 일정 정리</p>
                                    <p>배운 강의 내용 정리</p>
                                    <p>아이디어 정리</p>
                                    <p>기능</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">하루 일정 정리</p>
                                        <p>사용자가 하루동안의 일정을 입력하고, 시간대나 카테고리 등으로 일정을 분류하고 관리할 수 있도록 합니다.</p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">배운 강의 내용 정리</p>
                                        <p>앤츠네스트에서 제공하는 강의를 수강하고, 핵심 내용을 정리하여 저장하고 복습할 수 있도록 합니다.</p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">아이디어 정리</p>
                                        <p>사용자가 창의적인 아이디어를 기록하고, 관리하며, 효율적으로 활용할 수 있도록 합니다.</p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">기능</p>
                                        <ul>
                                            <li>사용자 인증과 권한 관리를 구현하여 개인 정보와 중요한 데이터를 보호합니다.</li>
                                            <li>대아토룰 클라우드에 안전하게 저장하고 백업할 수 있도록 합니다.</li>
                                            <li>사용자 인터페이스(UI)를 사용하기 쉽게 직관적으로 디자인하여 편리한 사용자 경험을 제공합니다.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                

                
            </div>
        </div>
    );
}