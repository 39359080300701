import React, { useEffect, useState } from "react";
import './style.css';
import ITImg1 from '../../images/ItService01.png';
import ITImg2 from '../../images/ItService02.png';
import ITImg3 from '../../images/ItService03.png';
import ITImg4 from '../../images/ItService04.png';
import TechImg1 from '../../images/Tech01.png';
import TechImg2 from '../../images/Tech02.png';
import TechImg3 from '../../images/Texh03.png';
import EduImg1 from '../../images/eduImg01.png';
import EduImg2 from '../../images/eduImg02.png';
import EduImg3 from '../../images/eduImg03.png';
import {Link as ScrollLink} from 'react-scroll';

export default function Service(){
    return(
        <div className="ServiceContents">
            <div className="HomeContainer">
                {/* 상단 텍스트 */}
                <div className="ServiceTopTxt">
                    <h1>서비스</h1>
                    <p>하드웨어 기초지식부터 소프트웨어 개발자로 소프트웨어 개발자에서 증강현실 개발자로 자연스러운 연계 학습 노드마스터를 통한 자연스러운 레퍼런스 정리</p>
                </div>
                {/* 스크롤 */}
                <div className="TabBtn">
                    <ScrollLink to='it-service' smooth={true} duration={500}><button>IT 서비스</button></ScrollLink>
                    <ScrollLink to='edu-service' smooth={true} duration={500}><button>교육 서비스</button></ScrollLink>
                    {/* <Link to="#it-service"><button>IT 서비스</button></Link>
                    <Link to="#edu-service"><button>교육 서비스</button></Link> */}
                </div>
                {/* It 서비스 */}
                <section id="it-service">
                    <h1 className="TabTitle">IT 서비스</h1>
                    <div>
                        {/* 첫번째 */}
                        <div className="ITContents">
                            <img src={ITImg1}/>
                            <div className="ITFirstBox">
                                <h2>하드웨어 서비스</h2>
                                <div className="ITCircle">
                                    <p>인프라 구축</p>
                                    <p>전산장비 유지보수</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">인프라 구축</p>
                                        <p>고객의 요구에 맞춰 다양한 하드웨어 요소를 고려하여 안정적인 IT 인프라를 구축해드립니다.
                                        </p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">전산장비 유지보수</p>
                                        <p>인프라 구축이 완료된 후에도 고객님의 인프라를 주기적으로 모니터링하고 
                                            유지보수를 진행합니다. 전산장비의 상태를 체크하여 장애나 문제를 예방합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 두번째 */}
                        <div className="ITContents">
                            <img src={ITImg2}/>
                            <div className="ITFirstBox">
                                <h2>IT 교육</h2>
                                <div className="ITCircle">
                                    <p>C# 객체지향 코딩 강의</p>
                                    <p>Unity 3D 강의</p>
                                    <p>리액트 강의</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">C# 객체지향 코딩 강의</p>
                                        <p>코딩에 대한 사전 지식이 없는 분들도 충분히 배울 수 있는 맞춤 강의입니다.
                                            C#문법을 언어적으로 표현하는 다양한 예제를 통해 이해하기 쉽고 세부적으로 정리, 설명, 학습입니다. 
                                        </p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">Unity 3D 강의</p>
                                        <p>가상현실(VR)및 증강현실(AR)기술을 활용하여 교육 및 훈련 분야에서도 사용됩니다. 
                                            EX)항공, 해상등 위험한 분야에서의 훈련, 의료 분야에서의 시뮬레이션 등에서 사용됩니다.
                                        </p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">리액트 JS 강의</p>
                                        <p>실무에서 많이 쓰이는 React와 함께 사용되는 모든 기술을 한번에 배울 수 있습니다. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 세번째 */}
                        <div className="ITContents">
                            <img src={ITImg3}/>
                            <div className="ITFirstBox">
                                <h2>3D 버추얼 스튜디오</h2>
                                <div className="ITCircle">
                                    <p>VR/AR 콘텐츠 촬영</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">3D 버추얼 스튜디오</p>
                                        <p>고급카메라, 모션 캡처 시스템, 초고해상도 그린 스크린 등 최신 기술과 장비를 사용하여
                                            가상 혹은 혼합 현실 콘텐츠를 제작합니다. 
                                        </p>
                                    </div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">VR/AR 콘텐츠 촬영</p>
                                        <p>VR/AR 기술을 활용하여 다양한 분야에서 콘텐츠를 촬영합니다. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 네번째 */}
                        <div className="ITContents">
                            <img src={ITImg4}/>
                            <div className="ITFirstBox">
                                <h2>3D 리소스 개발</h2>
                                <div className="ITCircle">
                                    <p>3D 모델링</p>
                                    <p>캐릭터 리깅</p>
                                    <p>모션캡처 애니메이션</p>
                                </div>
                                <div>
                                    <div className="gradationBox">
                                        <p className="colorTitle">3D 리소스 개발</p>
                                        <p>스튜디오 배경 및 3D 컨텐츠 개발에 필요한 리소스를 제작합니다.
                                            <ul>
                                                <li>모델링</li>
                                                <li>애니메이션</li>
                                                <li>리깅</li>
                                                <li>유니티 휴머노이드 작업</li>
                                            </ul>
                                        </p>
                                    </div>
                                    <h2>Technology</h2>
                                    <div className="gradationBoxes">
                                        <div className="gradationBox">
                                            <img src={TechImg1}/>
                                            <p>Z-Brush</p>
                                        </div>
                                        <div className="gradationBox">
                                            <img src={TechImg2}/>
                                            <p>Blender</p>
                                        </div>
                                        <div className="gradationBox">
                                            <img src={TechImg3}/>
                                            <p>Substance<br/>Paitner</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* 교육 서비스 */}
                <section id="edu-service">
                    <h1 className="TabTitle">교육 서비스</h1>
                    <div className="EduContents">
                        <div className="EduBoxFlex">
                            <div className="EduBox">
                                <img src={EduImg1}/>
                                <ul>
                                    <li>하드웨어 인프라 교육</li>
                                    <li>네트워크 인프라</li>
                                    <li>응용 소프트웨어</li>
                                    <li>계층간 통신</li>
                                </ul>
                            </div>
                            <div className="EduBox">
                                <img src={EduImg2}/>
                                <ul>
                                    <li>SPA 웹 개발</li>
                                    <li>모바일 앱</li>
                                    <li>빅데이터 구축</li>
                                </ul>
                            </div>
                            <div className="EduBox">
                                <img src={EduImg3}/>
                                <ul>
                                    <li>3D 그래픽 모델링, 애니메이션</li>
                                    <li>증강현실 개발</li>
                                    <li>인공지능 개발 및 분석</li>
                                </ul>
                            </div>
                        </div>
                        <div className="EduBoxFinal">
                            <h3>장점</h3>
                            <p>
                                쉬고 다양한 한국어 라이브러리 예제를 활용하여 디버깅 과정에서 스스로 코드를 자연스럽게 이해하여 프로그래밍 능력을 습득할 수 있습니다.
                                기존의 틀에서 벗어난 언어적인 개발 방식으로 자유로운 사고의 코딩이 가능합니다.
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};