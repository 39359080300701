import FutureIndustryImg from '../../images/FutureIndustryImg.png';
import SolutionImg from '../../images/SolutionImg.png';
import ServiceImg from '../../images/ServiceImg.png';
import NodeImg from '../../images/NodeImg.png';
import { Link } from 'react-router-dom';
import './style.css';

export default function RedirectGallery() {
    return (
      <>
        <section className='redirectGallery' >
          <article
            style={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              
            }}
          >
          <div className="container" >
            {/* 미래산업 */}
            <Link to={'/FutureIndustry'}  className="image-section1" >
              <div>
                <h1 className='mainTitleF'>미래산업</h1>
                <ul className='mainListF mTitle'>
                  <li>VR/AR</li>
                  <li>Web3</li>
                  <li>메타버스</li>
                  <li>인공지능</li>
                  <li>디지털 트윈</li>
                </ul>
              </div>
            </Link>

            {/* SI솔루션 */}
            <Link to={'/Solution'} className="image-section2" >
              <div>
                <h1 className='mainTitleS' >SI 솔루션</h1>
                <ul className='mainListS mTitle' >
                  <li>시스템 통합구축</li>
                  <li>ERP</li>
                  <li>HIS</li>
                  <li>MES</li>
                  <li>POP</li>
                </ul>
              </div>
            </Link>

            {/* 서비스 */}
            <Link to={'/Service'} className="image-section3">
              <div>
                <h1 className='mainTitleService'>서비스</h1>
                <ul className='mainListService mTitle' >
                  <li>하드웨어 서비스</li>
                  <li>IT교육</li>
                  <li>클라우드</li>
                  <li>버츄얼 촬영 스튜디오</li>
                </ul>
              </div>
            </Link>

            {/* 노드마스터 */}
            <Link to={'/NodeMaster'} className="image-section4" >
              <div>
                <h1 className='mainTitleNode' >노드마스터</h1>
                <ul className='mainListNode mTitle'>
                  <li>커뮤니티</li>
                  <li>엔츠톡</li>
                  <li>SkyScribe</li>
                  <li>AntsWiki</li>
                </ul>
              </div>
            </Link>
          </div>
          </article>
        </section>
      </>
    );
  }

 