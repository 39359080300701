import './style.css';
import FIImg1B from '../../images/FIImg01_big.png';
import FIImg2B from '../../images/FIImg02_big.png';
import FIImg3B from '../../images/FIImg03_big.png';
import FIImg4B from '../../images/FIImg04_big.png';
import FIImg5B from '../../images/FIImg05_big.png';
import FIImg6B from '../../images/FIImg06_big.png';
import FIImg7B from '../../images/FIImg07_big.png';
import FIImg8B from '../../images/FIImg08_big.png';
import FIImg9B from '../../images/FIImg09_big.png';
import FIImg10B from '../../images/FIImg10_big.png';
import FIImg11B from '../../images/FIImg11_big.png';
import FIImg12B from '../../images/FIImg12_big.png';
import FIImg13B from '../../images/FIImg13_big.png';
import FIImg14B from '../../images/FIImg14_big.png';
import FIImg15B from '../../images/FIImg15_big.png';
import SlideShowGallery from '../../components/Slide';

export default function FutureIndustry(){
    return(
        <div className='FutureIndustryContents'>
            <div className='HomeContainer'>
                {/* 상단 텍스트 */}
                <div className="FutureIndustryTopTxt">
                    <h1>미래산업</h1>
                </div>
                {/* 컨텐츠 */}
                <div>
                    {/* 첫번째 */}
                    <div className="FIContents">
                        <div className='ThumbnailViewer'>
                            {/* <img src={FIImg1}/> */}
                            <SlideShowGallery images={[FIImg1B, FIImg2B, FIImg3B]} />
                        </div>
                        <div className="FIFirstBox">
                            <h2>VR/AR</h2>
                            <div className="FICircle">
                                <p>증강현실 교육 시물레이션</p>
                            </div>
                            <div>
                                <div className="gradationBox">
                                    <p className="colorTitle">사업 개요</p>
                                    <p>
                                        <span>증강현실 솔루션</span> 중 하나로 다양한 분야의 교육에 활용하기 위한 
                                        <span>VR/AR교육 시물레이션 소프트웨어</span>제작 현장감을 가상으로 구현하여 
                                        누구나 준비단계에서부터 실전에 대비할 수 있도록 함
                                    </p>
                                </div>
                                <div className="gradationBox">
                                    <p className="colorTitle">응용 분야</p>
                                    <ul>
                                        <li><span>예술:</span>피아노, 기타</li>
                                        <li><span>의료:</span>신체 탐구, 가상 수술 시뮬레이션</li>
                                        <li><span>산업:</span>장비 실습, 안전 교육</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 두번째 */}
                    <div className="FIContents">
                        <div className='ThumbnailViewer'>
                        {/* <img src={FIImg2}/> */}
                        <SlideShowGallery images={[FIImg4B, FIImg5B, FIImg6B]} />
                        </div>
                        <div className="FIFirstBox">
                            <h2>Web3</h2>
                            <div className="FICircle">
                            </div>
                            <div>
                                <div className="gradationBox">
                                    <p className="colorTitle">사업 개요</p>
                                    <p>
                                        기존 중앙집중화된 인터넷 모델에서 탈중앙화와 개인 주권의 개념을 제공하며, 보안과 프라이버시를 강화
                                        혁신적인 서비스와 비즈니스 기회를 창출하고, 사용자들에게 보다 <span>탈중앙화된, 안전하고 개인 주권을 존중</span>하는
                                        <span>인터넷 환경을 제공</span>하고자 함
                                    </p>
                                </div>
                                <div className="gradationBox">
                                    <p className="colorTitle">응용 분야</p>
                                    <ul>
                                        <li>코인지갑</li>
                                        <li>블록체인 시스템</li>
                                        <li>NFT마켓</li>
                                        <li>가상화폐</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 세번째 */}
                    <div className="FIContents">
                        <div className='ThumbnailViewer'>
                            {/* <img src={FIImg3}/> */}
                            <SlideShowGallery images={[FIImg7B, FIImg8B, FIImg9B]} />
                        </div>
                        <div className="FIFirstBox">
                            <h2>Metaverse</h2>
                            <div className="FICircle">
                            </div>
                            <div>
                                <div className="gradationBox">
                                    <p className="colorTitle">사업 개요</p>
                                    <p>
                                        메타버스의 잠재력을 활용하여 혁신적인 비즈니스와 다양한 경제 활동을 구축하고, 
                                        사용자들에게 새로운 경험과 가치를 제공
                                    </p>
                                </div>
                                <div className="gradationBox">
                                    <p className="colorTitle">응용 분야</p>
                                    <ul>
                                        <li>메타버스 플랙폼 개발</li>
                                        <li>가상 현실 게임 개발</li>
                                        <li>메타버스 상거래 플랫폼 개발</li>
                                        <li>소셜 메타버스 플랫폼 개발</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 네번째 */}
                    <div className="FIContents">
                        <div className='ThumbnailViewer'>
                            {/* <img src={FIImg2}/> */}
                            <SlideShowGallery images={[FIImg10B, FIImg11B, FIImg12B]} />
                        </div>
                        <div className="FIFirstBox">
                            <h2>AI/Big Data</h2>
                            <div className="FICircle">
                            </div>
                            <div>
                                <div className="gradationBox">
                                    <p className="colorTitle">사업 개요</p>
                                    <p>
                                        AI 기반의 음성 비서, 이미지 인식 기술을 활용한 스마트 카메라, 자율 주행 기술 등 다양한 분야에서의 제품과 서비스를 제공,
                                        대용량의 데이터를 분석하고 예측 모델을 개발하여 데이터를 기반으로 한 전략적인 의사결정을 내리고, 비즈니스 프로세스를 최적화 할 수 있음
                                    </p>
                                </div>
                                <div className="gradationBox">
                                    <p className="colorTitle">응용 분야</p>
                                    <ul>
                                        <li>가상 현실 게임</li>
                                        <li>영어 회화 교육</li>
                                        <li>홀로그램 비서</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 다섯번째 */}
                    <div className="FIContents">
                        <div className='ThumbnailViewer'>
                            {/* <img src={FIImg2}/> */}
                            <SlideShowGallery images={[FIImg13B, FIImg14B, FIImg15B]} />
                        </div>
                        <div className="FIFirstBox">
                            <h2>Digital Twin</h2>
                            <div className="FICircle">
                            </div>
                            <div>
                                <div className="gradationBox">
                                    <p className="colorTitle">사업 개요</p>
                                    <p>
                                        현실 세계의 물리적인 개체나 프로세스를 디지털 형태로 모델링한
                                        가상의 쌍둥이 개체를 통해 실제 시스템의 상태와 동작을 실시간으로 모니터링하고, 예측 및 최적화를 수행
                                    </p>
                                </div>
                                <div className="gradationBox">
                                    <p className="colorTitle">응용 분야</p>
                                    <ul>
                                        <li>센서장비</li>
                                        <li>산업현장 교육</li>
                                        <li>훈련용 XR 시뮬레이터</li>
                                        <li>3D 콘텐츠 자동생성 및 경량화 솔루션</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};