import './style.css';
import SolIcon01 from '../../images/solIcon01.png';
import SolIcon02 from '../../images/solIcon02.png';
import SolFinal01 from '../../images/solFinalImg01.png';
import SolFinal02 from '../../images/solFinalImg02.png';
import SolFinal03 from '../../images/solFinalImg03.png';
import SolFinal04 from '../../images/solFinalImg04.png';
import solBottomBack from '../../images/solBottomBack.png';


export default function Solution(){
    return(
        <div className='SolutionContents'>
            <div className='HomeContainer'>
                {/* 상단 텍스트 */}
                <div className="SolutionTopTxt">
                    <h1>당신만을 위한 솔루션<br/>
                        <span>ANTSNEST</span>에서 구축하세요.
                    </h1>
                </div>

                {/* 첫번째 섹션 */}
                <div className='SolutionContents01'>
                        <div className='SolutionTxt SolutionTxt01'>
                            <h1>마이크로 서비스 아키텍쳐(모듈화 구축)</h1>
                            <p>ANTSNEST는 마이크로서비스 아키텍처와 모듈식 구축을 활용하여 간결하고 효율적인 서비스를 제공합니다. 시스템을 최소 기능<br/>
                                단위로 분해하여, 개발 위험과 비용을 효과적으로 모니터링합니다. 이 모듈화는 서비스의 재사용성과 유연성을 증가시키고, 다른<br/>
                                서비스에 기능을 적용하는 것을 간단하게 만듭니다.
                            </p>
                        </div>
            
                        <div className='SolutionTxt SolutionTxt02'>
                            <h1>VR/AR을 활용한 디지털 트윈 구현</h1>
                            <p>우리는 가상 및 증강 현실 기술을 사용하여 물리적 시스템의 디지털 트윈을 생성함으로써 전통적인 SI/SM 경계를 초월합니다. 우<br/>
                                리의 고급 증강 현실 플랫폼은 웹, 애플리케이션, 모바일 환경과 통합되어, 향상된 시뮬레이션, 예측 및 시스템 최적화를 가능하게<br/>
                                합니다.
                            </p>
                        </div>
                    
                        <div className='SolutionTxt SolutionTxt03'>
                            <h1>시스템 구축 개발자를 자사에서 직접 양성합니다.</h1>
                            <p>내부 개발자 교육으로 우리는 자체 재능을 키우는데 믿음을 가지고 있습니다. 우리의 개발자들은 직접 훈련을 받고 지속적인 학습<br/>
                                을 통해 새로운 기술에 노출됩니다. 최신 도구를 활용하여, 우리는 자동화의 정밀도를 추구하고 모든 운영에서 높은 품질 기준을 유<br/>
                                지합니다.
                            </p>
                        </div>
                </div>

                {/* 두번째 섹션 */}
                <div className='SolutionContents02'>
                    <div className='SolIconContents'>
                        <div className='SolImgTxt'>
                            <img src={SolIcon01}/>
                            <div className='SolIconTxt'>
                                <h2>체계적인 2인1조 프로젝트 코드 관리</h2>
                                <p>
                                    프로젝트 코드관리를 위한 2인 팀 관리법을 사용합니다.
                                    정기적인 코드 리뷰를 통해 가독성과 미래의 유지 보수를 보장하고, 효과적인 소프트웨어 수명 주기를 돕습니다.
                                </p>
                            </div>
                        </div>

                        <div className='SolImgTxt'>
                            <img src={SolIcon02}/>
                            <div className='SolIconTxt'>
                                <h2>최소한의 비용으로 <br/>
                                시스템 통합 구축에서 인프라 데이터 수집
                                </h2>
                                <p>
                                    백엔드 및 향후 빅데이터와 인공지능 활용범위까지 체계적으로 확장해 나갈 수 있도록 개발합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 세번째 섹션 */}
                <div className='SolutionContents03'>
                    <div className='Sol03ImgTxt'>
                        <img src={solBottomBack}/>
                        <div className='Sol03Txt'>
                            <div className='Sol03TopTxt'>
                                <h1>실전보다 더 실전같은 프로젝트</h1>
                                <p>다양한 인프라와 기술을 한 공간에서 경험할 수 있습니다.</p>
                            </div>
                            <div className='Sol03BottomTxt'>
                                <h1>숲을 보고 나무를 본다</h1>
                                <p>IT 기술 전체적으로 보고 모든 부분을 직접 구축하고 개발합니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 마지막 섹션 */}
                <div className='SolutionContents04'>
                    <h1>산업별 활용예제</h1>
                    <div className='SolFinalImgs'>
                        <div className='SolFinalFlex'>
                            <img src={SolFinal01}/>
                            <img src={SolFinal02}/>
                        </div>
                        <div className='SolFinalFlex'>
                            <img src={SolFinal03}/>
                            <img src={SolFinal04}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}