import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../images/MainLogo_Big.png';
import Popup from "../Popup";
import styled from 'styled-components';
import fileManager from '../../images/FileManager.png';
import BrandLogo from '../../images/BrandLogo.png';
import MarketLogo from '../../images/StoreLogo.png';
import AssetMarketLogo from '../../images/AssetStoreLogo.png';
import NodeLogo from '../../images/NodeLogo.png';
import AdminLogo from '../../images/AdminLogo.png'; //공장MES와 어울림
import RemoteDesktopLogo from '../../images/RemoteLogo.png';
import EstimateLogo from '../../images/EstimateLogo.png';
import bigData from '../../images/bigData.jpg';
import vrarLogo from '../../images/vrarLogo.png';
import logic from '../../images/logic.png';
import AILogo from '../../images/AILogo.png';
import web3 from '../../images/web3.png';
import MyLogo from '../../images/MyLogo.png';
import Skyscribe from '../../images/SkyscribeLogo.png';
import ganttChart from '../../images/gantchart.gif';
import AntsTalk from '../../images/AntsTalk.gif';
import Telant from '../../images/Telant.webp';
import toggleIcon from '../../images/X.png';
import './style.css';
import './Tab.css';
import Divider from './Divider.jsx';

export default function Navigator(){
    const [isToggled, setIsToggled] = useState(false);
    const [userToggled] = useState(false);
    // 팝업 열리고 닫는거
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleLogoClick = ()=>{
        setIsPopupOpen(true);
    }
    const closePopup = ()=>{
        setIsPopupOpen(false);
    }

      return(
        <header className="MainHeader" isToggled={isToggled} userToggled={userToggled}>
            <div className="headerContainer">
                <div className="logo">
                    <Link to={'/'} className="home" onClick={handleLogoClick}>
                        <div className="logo">
                            <img className="logoImg" src={Logo} />
                        </div>
                    </Link>
                </div>
                <Popup isOpen={isPopupOpen} handleClose={closePopup}/>
                <ul className="header__menulist">
                    <Link to={'/Company'} className="company" style={{ color: '#fff', textDecoration: 'none' }}><li>Company</li></Link>
                    <Link to={'/Service'} className="service" style={{ color: '#fff', textDecoration: 'none' }}><li>Service</li></Link>
                    <Link to={'/Business'} className="business" style={{ color: '#fff', textDecoration: 'none' }}><li>Business</li></Link>
                    <Link to={'/Contact'} className="contact" style={{ color: '#fff', textDecoration: 'none' }}><li>Contact</li></Link>
                </ul>
                <SlideTab/>
            </div>
        </header>
      );
}



function SlideTab() {
  const [isOpen, setIsOpen] = useState(false); 
  
  const ToggleImage = styled.img`
    cursor: pointer;
    margin-right: 10px;
    transition: all 0.3s ease;

    &:hover {
        transform: rotate(90deg);
    }
`;

  const toggleTab = () => setIsOpen(!isOpen);

  return (
    <>
        <SvgButton onClick={toggleTab}/>
        <div className={`tab-container ${isOpen ? 'open' : ''}`}>
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
        }}>
            <img className = "toggle-icon" src={toggleIcon} onClick={toggleTab}   style={{
             width: '20px',  // 이미지 크기 조절
             height: '20px',
            cursor: 'pointer',
            marginBottom: '4px',
            }}
            alt="toggle"/>
            </div>
            <section style={{
                width: '100%',
                background: '#fff',
                border: 'none',
                borderRadius: '30px',
                paddingTop : '10px',
                boxShadow: '0px 2px 0px 2px #f1f1f1',
            }}>
                <h2 style={{fontSize: '25px', width: '100%', textAlign: 'center'}}>AntsNest Apps</h2>
                <p style={{fontSize: '14px', width: '100%', textAlign: 'center'}}>
                    앤츠네스트가 제공하는<br/>다양한 서비스를 체험해보세요.
                </p>
                <div class="slide-container">
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: '10px',
                    justifyContent: 'space-evenly',
                    alignItems: 'start',
                    marginTop: '10px'
                }}>

                    <div style={{marginTop:'20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>고객관리 CRM</span>
                        <a href='https://app.antsnest.co.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={BrandLogo} 
                            alt="crm"/>
                        </a>
                    </div>

                    <div style={{marginTop:'20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>견적서</span>
                        <a href='https://estimate.antsnest.co.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px',
                            }} 
                            src={EstimateLogo} 
                            alt="견적서"/>
                        </a>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>원격 접속</span>
                        <a href='https://skynet.re.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={RemoteDesktopLogo} 
                            alt="RemoteDesktopLogo"/>
                        </a>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>파일 저장소</span>
                        <a href='https://file.skynet.re.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={fileManager} 
                            alt="File Manager"/>
                        </a>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>학습관리 LMS</span>
                        <a href='https://edu.antsnest.co.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={MyLogo} 
                            alt="LMS학습관리시스템"/>
                        </a>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>웹플레이어</span>
                        <a href='https://skyscribe.co.kr/'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={Skyscribe} 
                            alt="Skyscribe"/>
                        </a>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>쇼핑몰 SI</span>
                        <a href='https://market.antsnest.co.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                            }} 
                            src={MarketLogo} 
                            alt="MarketLogo"/>
                        </a>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>서비스마켓 SI</span>
                        <a href='https://store.antsnest.co.kr'>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                            }} 
                            src={AssetMarketLogo} 
                            alt="AssetStoreLogo"/>
                        </a>
                    </div>
                    <Divider />
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>데이터관리 툴</span>
                        {/* <a href='https://nodemaster.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={NodeLogo} 
                            alt="노드마스터"/>
                            </div>
                        {/* </a> */}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>사내 위키</span>
                        {/* <a href='https://wiki.antsnest.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={vrarLogo} 
                            alt="vrarLogo"/>
                        </div>
                        {/* </a> */}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>블록체인 관련 SI</span>
                        {/* <a href='https://w3.antsnest.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={web3} 
                            alt="web3"/>
                        </div>
                        {/* </a> */}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>인프라 관리</span>
                        {/* <a href='https://logic.antsnest.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={logic} 
                            alt="logic"/>
                        </div>
                        {/* </a> */}
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>앤츠톡</span>
                        {/* <a href='https://ai.nodemaster.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '24px'
                            }} 
                            src={AntsTalk} 
                            alt="앤츠톡"/>
                        </div>
                        {/* </a> */}
                    </div>

                    {/* <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{display: 'none', fontSize: '12px', textAlign: 'center'}}>AI 서비스 연동</span>
                        <a href='https://ai.nodemaster.co.kr'>
                            <img className="app-icon" style={{
                                display: 'none',
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '8px'
                            }} 
                            src={AILogo} 
                            alt="AI 서비스 연동"/>
                        </a>
                    </div> */}

                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <span style={{fontSize: '12px', textAlign: 'center'}}>간트 차트</span>
                        {/* <a href='https://logic.antsnest.co.kr'> */}
                        <div>
                            <img className="app-icon" style={{
                                width: '50px',
                                height: '50px',
                                background: '#fff',
                                borderRadius: '15px',
                                cursor: 'pointer',
                                border: '1px solid #e0e0e0',
                                marginTop: '8px',
                                marginBottom: '24px'
                            }} 
                            src={ganttChart} 
                            alt="gantt chart"/>
                        </div>
                        {/* </a> */}
                    </div>

                    </div>
                </div>
            </section>
        </div>
    </>
);


function SvgButton({ onClick }) {
    const [hover, setHover] = useState(false);
  
    // 호버 상태에 따라 변경될 인라인 스타일 정의
    const svgStyle = {
      position: 'fixed',
      fill: 'none',
      cursor: 'pointer',
      right:'20px',
      filter: hover ? 'drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25))' : 'none',
    };

    const containerStyle = {
        position: 'relative',
        width: '40px', // SVG의 width와 일치하게 조정
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
  
    return (
        <div style={containerStyle} onClick={onClick}>
            <svg
                width="40" // 여기를 100으로 조정
                height="40" // 여기를 100으로 조정
                style={svgStyle}
                viewBox="0 0 24 24" // viewBox는 그대로 유지하여 SVG 내부 요소의 비율을 유지
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {/* SVG의 원들 */}
                <circle cx="6" cy="6" r="2" fill="#fff" />
                <circle cx="12" cy="6" r="2" fill="#fff" />
                <circle cx="18" cy="6" r="2" fill="#fff" />
                <circle cx="6" cy="12" r="2" fill="#fff" />
                <circle cx="12" cy="12" r="2" fill="#fff" />
                <circle cx="18" cy="12" r="2" fill="#fff" />
                <circle cx="6" cy="18" r="2" fill="#fff" />
                <circle cx="12" cy="18" r="2" fill="#fff" />
                <circle cx="18" cy="18" r="2" fill="#fff" />
            </svg>
        </div>
    );
  }
}