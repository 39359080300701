import './style.css';
import {reqGet,reqPost} from '../../Service';

import { useState } from 'react';
export default function Contact(){
    const [mailUser,setMailUser]=useState({});
    const [mailAddress,setMailAddress]=useState({});
    const [contents,setContents]=useState({});

    return(
        <div className="ContactContents">
            <div className="HomeContainer">
                {/* 상단 텍스트 */}
                <div className="ContactTopTxt">
                    <h1>Contact</h1>
                    <p>ANTSNEST와 함께하세요.</p>
                </div>
                {/* 신청폼 */}
                <section className='ContactFormBox'>
                    <form id="contact-form">
                        <div class="form-group">
                            <label for="name">신청인(회사명)</label>
                            <input className='underline' type="text" id="name" name="name" onChange={(e)=>setMailUser(e.target.value)} required placeholder='신청인(회사명)'/>
                        </div>

                        <div class="form-group">
                            <label for="email">이메일</label>
                            <input className='underline' type="email" id="email" name="email" onChange={(e)=>setMailAddress(e.target.value)} required placeholder='이메일'/>
                        </div>

                        <div class="form-group">
                            <label for="message">문의사항</label>
                            <textarea id="message" name="message" rows="8" onChange={(e)=>setContents(e.target.value)}  required placeholder='문의사항'></textarea>
                        </div>
                        <button type="button" onClick={()=>reqPost('https://antsnest.co.kr/request/sendMail',{mailUser,mailAddress,contents},()=>{alert('신청을 완료했습니다.')})}>상담 신청하기</button>
                    </form>
            </section>
            </div>
        </div>
    );
};